import React, { useState } from "react";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null); // Manage active index in parent

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle the active item
  };

  return (
    <section className="relative z-20 overflow-hidden bg-white pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20">
              <span className="mb-2 block text-2xl font-semibold text-primary">
                FAQ
              </span>
              <h2 className="mb-4 text-3xl font-bold text-black sm:text-[40px]/[48px]">
                Any Questions? <span className="text-teal-600">Look Here</span>
              </h2>
              <p className="text-base text-body-color dark:text-dark-6">
              Our approach begins with a deep dive into your business goals, target audience, and market trends. We use data-driven insights to develop a tailored advertising strategy that aligns with your unique needs.
              </p>
            </div>
          </div>
        </div>

        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 lg:w-1/2">
            <AccordionItem
              header="What services does Lime Brands offer?"
              text="Lime Brands provide a diverse array of digital advertising services, such as paid media, programmatic advertising, branding campaigns. Our solutions are meticulously crafted to enhance performance and elevate your brand's presence in the digital landscape."
              isActive={activeIndex === 0} // Pass active state
              onClick={() => handleToggle(0)} // Pass toggle handler
            />
            <AccordionItem
              header="Does Lime Brand manage aspects of the campaign?"
              text="Lime Brands handle every aspect of your campaign journey from strategic planning and creative development to execution, optimization, and detailed reporting. This comprehensive approach guarantees that your campaigns receive unwavering support."
              isActive={activeIndex === 1}
              onClick={() => handleToggle(1)}
            />
            <AccordionItem
              header="What platforms does Lime Brands use for advertising?"
              text="Lime Brands utilizes a variety of platforms for effective advertising, including major social media channels like Facebook, Instagram, and LinkedIn to reach targeted audiences. We also leverage search engines like Google for PPC campaigns and programmatic advertising."
              isActive={activeIndex === 2}
              onClick={() => handleToggle(2)}
            />
          </div>
          <div className="w-full px-4 lg:w-1/2">
            <AccordionItem
              header="What is the typical timeframe for seeing results?"
              text="The timeline for seeing results from a digital marketing agency can vary based on several factors, including the specific strategies employed, the complexity of the campaigns, and the industry landscape."
              isActive={activeIndex === 3}
              onClick={() => handleToggle(3)}
            />
            <AccordionItem
              header="What results are expected from Lime Brands?"
              text="Lime Brands aims to deliver measurable results, including increased brand awareness, higher engagement rates, and improved conversion rates. Clients can expect detailed analytics and insights that demonstrate campaign performance and ROI."
              isActive={activeIndex === 4}
              onClick={() => handleToggle(4)}
            />
            <AccordionItem
              header="What are goals and objectives in terms of service for Lime Brands?"
              text="The goals and objectives of a digital advertising agency focus on enhancing brand visibility and driving engagement through targeted campaigns Striving to build long-term relationships with clients by delivering consistent performance."
              isActive={activeIndex === 5}
              onClick={() => handleToggle(5)}
            />
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 right-0 z-[-1]">
        <svg
          width="1440"
          height="886"
          viewBox="0 0 1440 886"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="1308.65"
              y1="1142.58"
              x2="602.827"
              y2="-418.681"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#3056D3" stop-opacity="0.36" />
              <stop offset="1" stop-color="#F5F2FD" stop-opacity="0" />
              <stop offset="1" stop-color="#F5F2FD" stop-opacity="0.096144" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </section>
  );
};

const AccordionItem = ({ header, text, isActive, onClick }) => {
  return (
    <div className="mb-8 w-full rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-8 lg:px-6 xl:px-8">
      <button
        className={`faq-btn flex w-full text-left`}
        onClick={onClick}
      >
        <div className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-primary/5 text-primary dark:bg-white/5">
          <svg
            className={`fill-primary stroke-primary duration-200 ease-in-out ${isActive ? "rotate-180" : ""}`}
            width="17"
            height="10"
            viewBox="0 0 17 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
            />
          </svg>
        </div>

        <div className="w-full">
          <h4 className="mt-1 text-lg font-semibold text-body-color dark:text-dark-6">
            {header}
          </h4>
        </div>
      </button>

      <div
        className={`pl-[62px] duration-200 ease-in-out ${isActive ? "block" : "hidden"}`}
      >
        <p className="py-3 text-base leading-relaxed text-gray-400">
          {text}
        </p>
      </div>
    </div>
  );
};

export default Accordion;




// import React, { useState } from "react";

// const Accordion = () => {
    
//   return (
//     <section className="relative z-20 overflow-hidden bg-white pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
//       <div className="container mx-auto">
//         <div className="-mx-4 flex flex-wrap">
//           <div className="w-full px-4">
//             <div className="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20">
//               <span className="mb-2 block text-2xl font-semibold text-primary">
//                 FAQ
//               </span>
//               <h2 className="mb-4 text-3xl font-bold text-black sm:text-[40px]/[48px]">
//                 Any Questions? <span className="text-pink-500">Look Here</span>
//               </h2>
//               <p className="text-base text-body-color dark:text-dark-6">
//                 There are many variations of passages of Lorem Ipsum available
//                 but the majority have suffered alteration in some form.
//               </p>
//             </div>
//           </div>
//         </div>

//         <div className="-mx-4 flex flex-wrap">
//           <div className="w-full px-4 lg:w-1/2">
//             <AccordionItem
//               header="What services does Lime Brand offer?"
//               text="Lime Brand provide a diverse array of digital advertising services, such as paid media, programmatic advertising, branding campaigns. Our solutions are meticulously crafted to enhance performance and elevate your brand's presence in the digital landscape."
//             />
//             <AccordionItem
//               header="Does Lime Brand manage aspects of the campaign? "
//               text="Lime Brand handle every aspect of your campaign journey from strategic planning and creative development to execution, optimization, and detailed reporting. This comprehensive approach guarantees that your campaigns receive unwavering support."
//             />
//             <AccordionItem
//               header="How long we deliver your first blog post?"
//               text="It takes 2-3 weeks to get your first blog post ready. That includes the in-depth research & creation of your monthly content marketing strategy that we do before writing your first blog post, Ipsum available ."
//             />
//           </div>
//           <div className="w-full px-4 lg:w-1/2">
//             <AccordionItem
//               header="How long we deliver your first blog post?"
//               text="It takes 2-3 weeks to get your first blog post ready. That includes the in-depth research & creation of your monthly content marketing strategy that we do before writing your first blog post, Ipsum available ."
//             />
//             <AccordionItem
//               header="How long we deliver your first blog post?"
//               text="It takes 2-3 weeks to get your first blog post ready. That includes the in-depth research & creation of your monthly content marketing strategy that we do before writing your first blog post, Ipsum available ."
//             />
//             <AccordionItem
//               header="How long we deliver your first blog post?"
//               text="It takes 2-3 weeks to get your first blog post ready. That includes the in-depth research & creation of your monthly content marketing strategy that we do before writing your first blog post, Ipsum available ."
//             />
//           </div>
//         </div>
//       </div>

//       <div className="absolute bottom-0 right-0 z-[-1]">
//         <svg
//           width="1440"
//           height="886"
//           viewBox="0 0 1440 886"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             opacity="0.5"
//             d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
//             fill="url(#paint0_linear)"
//           />
//           <defs>
//             <linearGradient
//               id="paint0_linear"
//               x1="1308.65"
//               y1="1142.58"
//               x2="602.827"
//               y2="-418.681"
//               gradientUnits="userSpaceOnUse"
//             >
//               <stop stop-color="#3056D3" stop-opacity="0.36" />
//               <stop offset="1" stop-color="#F5F2FD" stop-opacity="0" />
//               <stop offset="1" stop-color="#F5F2FD" stop-opacity="0.096144" />
//             </linearGradient>
//           </defs>
//         </svg>
//       </div>
//     </section>
//   );
// };

// const AccordionItem = ({ header, text }) => {
//   const [active, setActive] = useState(false);

//   // Update handleToggle to accept event as a parameter
//   const handleToggle = (event) => {
//     event.preventDefault();
//     setActive(!active);
//   };

//   return (
//     <div className="mb-8 w-full rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-8 lg:px-6 xl:px-8">
//       <button
//         className={`faq-btn flex w-full text-left`}
//         onClick={(event) => handleToggle(event)} // Pass event to handleToggle
//       >
//         <div className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-primary/5 text-primary dark:bg-white/5">
//           <svg
//             className={`fill-primary stroke-primary duration-200 ease-in-out ${
//               active ? "rotate-180" : ""
//             }`}
//             width="17"
//             height="10"
//             viewBox="0 0 17 10"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
//               fill=""
//               stroke=""
//             />
//           </svg>
//         </div>

//         <div className="w-full">
//           <h4 className="mt-1 text-lg font-semibold text-body-color dark:text-dark-6">
//             {header}
//           </h4>
//         </div>
//       </button>

//       <div
//         className={`pl-[62px] duration-200 ease-in-out ${
//           active ? "block" : "hidden"
//         }`}
//       >
//         <p className="py-3 text-base leading-relaxed text-gray-400">
//           {text}
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Accordion;
