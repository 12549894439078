import React from 'react';
import { motion } from 'framer-motion';
import { FiZap, FiHome, FiVideo, FiTarget, FiSettings, FiBarChart } from 'react-icons/fi';
import { FiCheckCircle } from 'react-icons/fi';
import VideoCarousel from './Carousel/VideoCarousel';
import Accordion from './Accordion/Accordion';
import { Helmet } from 'react-helmet';


  const steps = [
    {
      title: 'Audience Discovery',
      subtitle: 'Knowing Ideal Customer Needs',
      description: 'We dig deep into data to understand your audience’s needs, behaviours, and preferences, ensuring every campaign speaks directly to the people who matter most to your brand.',
      icon: '🔍', // You can replace this with an image or SVG if needed
    },
    {
      title: 'Tailored Strategy',
      subtitle: 'Crafting Campaigns That Convert',
      description: 'From social media to search engines, we design custom strategies that align with your business goals, using targeted messaging and smart tactics to drive results.',
      icon: '🎯',
    },
    {
      title: 'Creative Content',
      subtitle: 'Ads That Inspire and Engage',
      description: 'Our team of designers, writers, and creators develop eye-catching visuals and persuasive copy that make your brand stand out, whether it’s a banner ad, video, or carousel.',
      icon: '🎨',
    },
    {
      title: 'Precision Targeting',
      subtitle: 'Reaching the Right People, Every Time',
      description: 'Using advanced data analytics and programmatic tools, we pinpoint your ideal audience, ensuring your ads appear in front of the right eyes at the right time for maximum impact.',
      icon: '📈',
    },
    {
      title: 'Real-Time Optimization',
      subtitle: 'Continuous Improvement, Real Results',
      description: 'We monitor your campaigns in real time, analysing performance metrics to tweak, test, and optimize, ensuring your ads stay fresh and deliver the best possible return on investment.',
      icon: '⚙️',
    },
    {
      title: 'In-Depth Reporting',
      subtitle: 'Transparency and Insight at Every Step',
      description: 'With detailed, easy-to-understand reports, we show you exactly how your campaigns are performing, offering clear insights into what’s working and where we can push further for even better results.',
      icon: '📊',
    },
  ]
  // Card component to display each block with icon, number, and label

  
  const Home = () => {
  
  

  return (
    <>
    <Helmet>
      <title>Lime Brands - A Digital Driven Advertising Solutions</title>
      <meta  name='description' content='Targeted paid media campaigns for maximum visibility, programmatic advertising for seamless, automated reach, and engaging social media marketing alongside impactful branding campaigns elevate brands identity'/>
      <meta name='keywords' content='Digital Advertising Agency, Paid Media Services, Programmatic Advertising, Branding Campaigns, Digital Marketing Solutions, Online Advertising Experts, Social Media Advertising, PPC Management, Remarketing Services, WhatsApp Automation for Brands, Data-Driven Advertising, Digital Ad Campaign Optimization, Performance Marketing Agency, Video and Display Advertising' />
    </Helmet>
    <section className=" py-10 lg:py-20">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between mt-10 px-4">
        {/* Text Section */}
        <div className="lg:w-1/2">
        
          <h1 data-aos="fade-up"
          className="text-3xl lg:text-5xl font-bold text-gray-900 mb-4 leading-tight">
            Results You Can Count On,  <br />
            <span className="text-teal-600">Experience You Can Trust</span>
          </h1>
          <p data-aos="fade-up" data-aos-delay="500" 
          className="text-gray-500 mb-6">
            Combines reliability and experience in one compelling statement. It reflects our unwavering commitment to delivering measurable success through proven expertise. We consistently achieve impactful, data-driven outcomes tailored to your business goals.
          </p>

         
        </div>

        {/* Image Section */}
        <div data-aos="zoom-in" 
        className="lg:w-1/2 mt-10 lg:mt-0">
          <img data-aos="slide-left"
          data-aos-delay="300"
            className="mx-auto"
            src="/assets/home.png"
            alt="Phone Mockup"
          />
        </div>
      </div>
    </section>
    <section className="py-12 bg-gray-50">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Title Section */}
        <div className="text-center mb-12">
          <h2 data-aos="fade-up" data-aos-delay="400" className="text-3xl lg:text-4xl font-bold text-gray-900">
          DEFINED
          </h2>
          <p data-aos="fade-up" data-aos-delay="600" className="text-gray-500 mt-4 px-4">
          We are your gateway to unlocking the potential of online spaces. Behind the scenes, We have our team of experts blend art and science to craft campaigns that captivate, convert, and create lasting customer relationship.           
          </p>
        </div>

        {/* Feature Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          
          {/* Card 1 - Audience Discovery */}
          <div data-aos="fade-up" className="relative bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-500 p-3 rounded-full">
              <FiZap className="text-white w-6 h-6" />
            </div>
            <div className="mt-12 text-center">
              <h3 className="text-lg font-bold text-gray-900 mb-2">Social Media Magic - Ads that Captivate & Connect</h3>
              <p className="text-gray-500 mb-4">
              Our social media advertising does not just appear; it stops users mid-scroll. We craft campaigns that spark conversations, ignite engagement, and grow communities.              
              </p>
            </div>
          </div>

          {/* Card 2 - Tailored Strategy */}
          <div data-aos="fade-up" className="relative bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-pink-500 p-3 rounded-full">
              <FiHome className="text-white w-6 h-6" />
            </div>
            <div className="mt-12 text-center">
              <h3 className="text-lg font-bold text-gray-900 mb-2">Programmatic Power - Smarter Ads, Faster Results</h3>
              <p className="text-gray-500 mb-4">
              Let algorithms do the heavy lifting. Our programmatic advertising leverages AI to deliver your ads to the right people, in the right places, at the right time. 
              </p>
            </div>
          </div>

          {/* Card 3 - Creative Content */}
          <div data-aos="fade-up" className="relative bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-green-500 p-3 rounded-full">
              <FiVideo className="text-white w-6 h-6" />
            </div>
            <div className="mt-12 text-center">
              <h3 className="text-lg font-bold text-gray-900 mb-2"> Dynamic Display Advertising - Get Seen, Stay Remembered</h3>
              <p className="text-gray-500 mb-4">
              We create stunning, display ads that catch the eye and keep your brand top of mind. Whether it is captivating visuals or interactive banners, we spread your message.
              </p>
            </div>
          </div>

          {/* Card 4 - Precision Targeting */}
          <div data-aos="fade-up" className="relative bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-purple-500 p-3 rounded-full">
              <FiTarget className="text-white w-6 h-6" />
            </div>
            <div className="mt-12 text-center">
              <h3 className="text-lg font-bold text-gray-900 mb-2"> Performance Optimization - Every Campaign, Perfected   </h3>
              <p className="text-gray-500 mb-4">
              Our work does not stop at launch. We continuously monitor, tweak, and optimize your campaigns to ensure peak performance. Real-time analytics, and in-depth insights.            

              </p>
            </div>
          </div>

          {/* Card 5 - Real-Time Optimization */}
          <div data-aos="fade-up" className="relative bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-yellow-500 p-3 rounded-full">
              <FiSettings className="text-white w-6 h-6" />
            </div>
            <div className="mt-12 text-center">
              <h3 className="text-lg font-bold text-gray-900 mb-2"> Video Advertising - Tell Your Story, Inspire Action</h3>
              <p className="text-gray-500 mb-4">
              From YouTube to OTT, our video ads are designed to evoke emotion, inform, and inspire action. We craft compelling visual stories that resonate with your audience.           
              </p>
            </div>
          </div>

          {/* Card 6 - In-Depth Reporting */}
          <div data-aos="fade-up" className="relative bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 p-3 rounded-full">
              <FiBarChart className="text-white w-6 h-6" />
            </div>
            <div className="mt-12 text-center">
              <h3 className="text-lg font-bold text-gray-900 mb-2">  Cross-Platform Campaigns - Unified, Process channel </h3>
              <p className="text-gray-500 mb-4">
              We bring your brands story to life across multiple channels—from search and social to display and video. Every touchpoint is optimized to deliver a consistent experience.      
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <section className="py-16 px-12 ">
      <div data-aos="fade-up" 
      className="container mx-auto px-6 md:px-12 lg:px-24 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-md ">
        <div className="flex flex-col lg:flex-row items-center gap-10">
          {/* Image Section */}
          <div className="w-full lg:w-1/2">
            <div className="relative">
              {/* Background Circle */}
              <div className="absolute inset-0  rounded-full w-96 h-96 lg:w-[500px] lg:h-[500px] -z-10 top-0 left-1/2 transform -translate-x-1/2 lg:translate-x-0 lg:-left-10"></div>
              {/* Phone Image */}
              <img
                src="/assets/hom12.png"
                alt="Phone"
                className="w-full mx-auto animate-fade-in"
              />
            </div>
          </div>

          {/* Text Section */}
          <div className="w-full lg:w-1/2">
          
            <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 mt-3">
            Driving Engagement & Conversions with Data-Driven Strategies
            </h2>
            <p className="mt-4 text-gray-500">
            We use online channels like social media, search engines, and email to promote brands, products, or services. It focuses on targeting specific audiences with personalized, data-driven strategies to drive engagement and conversions.            </p>

            {/* Feature List */}
            <div className="mt-8 space-y-6">
              {/* Feature 1 */}
              <div className="flex items-start space-x-4">
                <FiCheckCircle className="text-green-500 w-10 h-10" />
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">
                  Targeted Campaigns 
                  </h3>
                  <p className="text-gray-500">
                   Utilizing social media, search engines, and email, we tailor personalized strategies to connect with specific audiences.
                  </p>
                </div>
              </div>

              {/* Feature 2 */}
              <div className="flex items-start space-x-4">
                <FiCheckCircle className="text-green-500 w-10 h-10" />
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">
                  Data-Driven Results 
                  </h3>
                  <p className="text-gray-500">
                  We focus on driving engagement and conversions through smart, analytics-backed decisions for optimal brand growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <div data-aos="fade-up" 
    class="flex flex-col lg:flex-row justify-center items-center gap-6 mt-10 lg:mt-20 "  style={{ backgroundColor: '#0e1133' }}>
  <div class="cards w-full lg:w-1/2 flex flex-col justify-center items-center gap-6 mt-10 lg:mt-0">
    <div class="card red">
      <p class="tip">Hover Me</p>
      <p class="second-text">Lorem Ipsum</p>
    </div>
    <div class="card blue">
      <p class="tip">Hover Me</p>
      <p class="second-text">Lorem Ipsum</p>
    </div>
    <div class="card green">
      <p class="tip">Hover Me</p>
      <p class="second-text">Lorem Ipsum</p>
    </div>
  </div>
  <div class="w-full lg:w-1/2 flex justify-center items-center">
    <img src="https://appiev.quomodosoft.com/static/media/features-thumb-2.e305e3ad.png" alt="Phone Image" class="w-72 lg:w-96 rounded-xl shadow-lg" />
  </div>
</div> */}
<section className="bg-white  text-gray-900 dark:text-gray-100 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-center text-4xl font-extrabold mb-12 text-teal-600">How Does It Work?</h2>
        
        <div className="space-y-12 lg:space-y-0 lg:grid lg:grid-cols-6 lg:gap-12">
          {steps.map((step, index) => (
            <motion.div 
              key={index} 
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className="col-span-6 lg:col-span-2 bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition-transform duration-500"
            >
              <div className="flex items-center space-x-4 mb-4">
                <span className="text-4xl">{step.icon}</span>
                <div>
                  <h3 className="text-xl font-semibold text-black">{step.title}</h3>
                  <p className="text-gray-500 dark:text-gray-400">{step.subtitle}</p>
                </div>
              </div>
              <p className='text-gray-500'>{step.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
{/* <section data-aos="fade-up" 
className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4">
       
        <div className="text-left mb-12">
          <h2 className="text-4xl font-bold mb-2">How does it work</h2>
          <p className="text-gray-600 text-lg">
            The full monty spiffing good time no biggie cack grub fantastic.
          </p>
        </div>

       
        <div ref={ref} className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          <Card icon="👥" count={count1} label="Active Installation" bgColor="bg-blue-100" />
          <Card icon="🏆" count={count2} label="Active Installation" bgColor="bg-orange-100" />
          <Card icon="😀" count={count3} label="Active Installation" bgColor="bg-green-100" />
          <Card icon="💬" count={count4} label="Active Installation" bgColor="bg-pink-100" />
        </div>
      </div>
    </section>   */}
   
   <VideoCarousel />
    <Accordion />
    </>
  );
};

export default Home;
