import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import { Route, Routes, useLocation } from 'react-router-dom';
import Service from './components/Service/Service';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
// import BlogPost from './components/Blog/BlogPost';
// import BlogMain from './components/Blog/BlogsMain';
import AOS from 'aos';
import "aos/dist/aos.css";
import React, { useEffect } from 'react';
import PrivacyPolicy from './components/Privacy/PrivacyPolicy';
import TermsAndConditions from './components/Privacy/TermAndCondition';
const App = () => {
  React.useEffect(() =>{
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      offset: 100,
      delay: 100,
    });
    AOS.refresh();
  }, []);
  const location = useLocation()
  useEffect(() => {
  
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
    <Navbar />
   <Routes>
    <Route path='/' exact element={<Home />} />
    <Route path='/service' element={<Service />}/>
    <Route path='/about-us' element={<About />} />
    <Route path='/contact-us' element={<Contact />} />
    <Route path='/privacy-policy'  element={<PrivacyPolicy />} />
    <Route path='/term-and-condition' element={<TermsAndConditions />} />
    {/* <Route path="/blog" element={<BlogMain />} />
    <Route path="/blog/:id" element={<BlogPost />} /> */}
   </Routes>
    <Footer />
    </>
  );
}

export default App;
