import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false); // State for mobile menu
  const [isScrolled, setIsScrolled] = useState(false); // State to manage scroll effect

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Effect to change navbar styles on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Close mobile menu when resizing to desktop view
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsOpen(false); // Close mobile menu on desktop view
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav
      className={`fixed w-full z-50 transition-all duration-500 ease-in-out ${
        isScrolled ? "shadow-md" : ""
      } bg-white`} // Ensure background is always white
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
        <div className="flex-shrink-0">
        <a href="/" >
            <img
              className="h-28 w-36 object-contain"  // Adjust height and width explicitly
              src="/logo.jpeg"   // Update with correct image path
              alt="App Logo"
            />
              </a>
          </div>
          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-8 text-xl">
            <a href="/" className="text-gray-800 hover:text-teal-700">
              Home
            </a>
            <a href="/service" className="text-gray-800 hover:text-teal-700">
              Service
            </a>
            <a href="/about-us" className="text-gray-800 hover:text-teal-700">
              About Us
            </a>
            {/* <a href="/blog" className="text-gray-800 hover:text-gray-600">
              Blogs
            </a> */}
            <a href="/contact-us" className="text-gray-800 hover:text-teal-700">
              Contact
            </a>
          </div>
          {/* Get Started Button */}
          <div className="hidden md:block">
            <Link to="/contact-us">
              <button className="text-white bg-teal-500 hover:bg-teal-700 px-4 py-2 rounded-full">
                Contact Us
              </button>
            </Link>
          </div>
          {/* Mobile Menu Button */}
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray-800 hover:text-gray-600 focus:outline-none focus:text-gray-600"
            >
              {!isOpen ? <FaBars className="h-6 w-6" /> : <FaTimes className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-white shadow-lg">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a href="/" className="block text-gray-800 hover:text-gray-600">
              Home
            </a>
            <a href="/service" className="block text-gray-800 hover:text-gray-600">
              Service
            </a>
            {/* <a href="/blog" className="block text-gray-800 hover:text-gray-600">
              Blog
            </a> */}
            <a href="/about-us" className="text-gray-800 hover:text-gray-600">
              About Us
            </a>
            <a href="/contact-us" className="block text-gray-800 hover:text-gray-600">
              Contact
            </a>
          </div>
          <div className="px-5 py-4">
            <Link to="/contact-us">
              <button className="text-white bg-teal-500 hover:bg-teal-600 w-full px-4 py-2 rounded-full">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;


// import React, { useState } from 'react';
// import { FaBars, FaTimes } from 'react-icons/fa';

// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <nav className="bg-gray-100 text-gray-800">
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="flex justify-between items-center h-20 ">
//           <div className="flex-shrink-0">
//             <img
//               className="h-8 w-8"
//               src="https://via.placeholder.com/50"
//               alt="App Logo"
//             />
//           </div>
//           <div className="hidden md:flex space-x-8 text-lg">
//             <a href="/" className="text-gray-800 hover:text-gray-600">Home</a>
//             <a href="/service" className="text-gray-800 hover:text-gray-600">Service</a>
//             <div className="relative">
//               <button className="text-gray-800 hover:text-gray-600 inline-flex items-center">
//                 Pages <svg className="w-5 h-5 ml-1" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M19 9l-7 7-7-7"></path></svg>
//               </button>
//               <div className="absolute hidden group-hover:block mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
//                 <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
//                   <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Sub Page 1</a>
//                   <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Sub Page 2</a>
//                 </div>
//               </div>
//             </div>
//             <a href="#" className="text-gray-800 hover:text-gray-600">News</a>
//             <a href="#" className="text-gray-800 hover:text-gray-600">Contact</a>
//           </div>
//           <div className="hidden md:block">
//             <button className="text-white bg-pink-600 px-4 py-2 rounded-full">Get Started</button>
//           </div>
//           <div className="-mr-2 flex md:hidden">
//             <button onClick={toggleMenu} className="text-gray-800 hover:text-gray-600 focus:outline-none focus:text-gray-600">
//               {!isOpen ? (
//                 <FaBars className="h-6 w-6" />
//               ) : (
//                 <FaTimes className="h-6 w-6" />
//               )}
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Mobile Menu */}
//       {isOpen && (
//         <div className="md:hidden">
//           <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
//             <a href="/" className="block text-gray-800 hover:text-gray-600">Home</a>
//             <a href="#" className="block text-gray-800 hover:text-gray-600">Service</a>
//             <a href="#" className="block text-gray-800 hover:text-gray-600">Pages</a>
//             <a href="#" className="block text-gray-800 hover:text-gray-600">News</a>
//             <a href="#" className="block text-gray-800 hover:text-gray-600">Contact</a>
//           </div>
//           <div className="px-5 py-4">
//             <button className="text-white bg-pink-600 w-full px-4 py-2 rounded-full">Get Started</button>
//           </div>
//         </div>
//       )}
//     </nav>
//   );
// };

// export default Navbar;
