import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const PlatformsGrid = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Animation duration
  }, []);

  const platforms = [
    { name: 'One Card', logo: '/assets/onecard.png' },
    { name: 'Fibe', logo: '/assets/fibe.jpg' },
    { name: 'JAR', logo: '/assets/jar.webp' },
    { name: 'Kreditbee', logo: '/assets/kredibee.png' },
    { name: 'Ajaib', logo: '/assets/ajaib.png' },
    { name: 'Fi Money', logo: '/assets/fimoney.png' },
    { name: 'Acko', logo: '/assets/acko.png' },
    { name: 'Rupee', logo: '/assets/rupee.png' },
    { name: 'Cadbury', logo: '/assets/cadbury.png' },
    { name: 'Probo', logo: '/assets/probo.png' },

  ];

  return (
    <div className="bg-white py-16 px-4" data-aos="fade-up">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-3xl font-bold text-gray-800 sm:text-4xl">
        We partner with some of the best and boldest brands.
        </h2>
        <p className="mt-4 text-lg text-gray-500">
          Join over 150+ businesses worldwide.
        </p>

        {/* Centered Grid of logos */}
        <div className="mt-10 flex justify-center">
          <div className="grid gap-8 grid-cols-2 sm:grid-cols-3 lg:grid-cols-5">
            {platforms.map((platform, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center bg-white shadow-lg p-4 rounded-lg"
                data-aos="fade-up"
              >
                <img src={platform.logo} alt={`${platform.name} logo`} className="max-h-16" />
                <p className="mt-2 text-lg text-bold text-gray-700">{platform.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformsGrid;
