import React, { useState } from "react";
import Http from "../../Http";
import { Contact_Us_API } from "../../Helper";
import { Helmet } from "react-helmet";
const Contact = () => {
    const backgroundImageUrl = "/assets/contact.jpeg"; 
      const initialFormData = {
        name: "",
        email: "",
        subject: "",
        message: "",
        phone: "",
      };
      const [formData, setFormData] = useState(initialFormData);
      const [loading, setLoading] = useState(false);
      const [error, setError] = useState(null);
      const [success, setSuccess] = useState(false);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
    
        if (name === 'name') {
          const pattern = /^[A-Za-z\s]+$/;
          if (!pattern.test(value)) {
            setError("Name should contain only letters and spaces.");
          } else {
            setError(null);
          }
        }
      
        if (name === 'email') {
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailPattern.test(value)) {
            setError("Please enter a valid email address.");
          } else {
            setError(null);
          }
        }
      
         // Validation for phone number (accepts only numeric input with 10 to 12 digits)
      if (name === 'phone') {
        const phonePattern = /^\d{10,12}$/; // Accepts numeric input with 10 to 12 digits
        if (!phonePattern.test(value)) {
          setError("Please enter a valid phone number with 10 to 12 digits.");
        } else {
          setError(null);
        }
      }
    
      
      
    
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
       
        if (!formData.name.trim() || !formData.phone.trim() || !formData.email.trim() || !formData.subject.trim() || !formData.message.trim()) {
          setError("Please fill out all fields.");
          return;
        }
    
        
        if (error) {
          return;
        }
    
        setLoading(true);
    
        try {
          const response = await Http.post(Contact_Us_API, formData);
          console.log("Response:", response);
          setSuccess(true);
          resetForm();
        } catch (error) {
          console.error("Error sending data:", error);
          setError("Failed to send data. Please try again later.");
        } finally {
          setLoading(false);
        }
      };
    
      const resetForm = () => {
        setFormData(initialFormData);
      };
  return (
    <>
     <Helmet>
      <title>Lime Brands Customer Care Toll Free Numbers & Contact Details</title>
      <meta  name='description' content='Get Lime Brands contact details, customer care helpline number & toll free numbers in case you require any assistance. You can call us at Lime brands toll free number for any help.'/>
    </Helmet>
    <div className="mx-auto max-w-screen-xl py-24 px-6 ">
        <div className="relative bg-cover bg-center h-80 md:h-96 flex items-center overflow-hidden">
          <div className="absolute inset-0 bg-black opacity-20"></div>
          <div className="absolute inset-0 z-10 flex flex-col items-center justify-center text-white text-center">
            <h1 className="text-3xl md:text-4xl lg:text-7xl font-bold mb-4 text-white">We are all ears!</h1>
            {/* <p className="text-sm md:text-base lg:text-3xl mb-6 text-green-500">
            All here and All yours.
            </p> */}
           
          </div>
          <img className="absolute inset-0 object-cover w-full h-full" src={backgroundImageUrl} alt="Background" />
        </div>
      </div>
    <section className="py-10 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col md:flex-row bg-white p-8 rounded-lg shadow-lg">
          {/* Left side - Contact Information */}
          <div className="md:w-1/3 bg-gray-100 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Get in touch</h2>
            <p className="text-gray-500 mb-8">Looking for help? Fill the form and start a new adventure.</p>

            {/* Contact Information */}
            <div className="space-y-8">
              <div>
                <h4 className="font-semibold text-lg text-gray-700">Headquarters</h4>
                <p className="text-gray-600">Unit No. 316, 3rd Floor, Tower-c, Klj Noida One, Block-b, Plot No. 8, Sector 62, Noida, Gautam Buddha Nagar, Noida, Uttar Pradesh, India, 201301</p>
              </div>

              <div>
                <h4 className="font-semibold text-lg text-gray-700">Support</h4>
                <p className="text-gray-600"> shivangi@limebrands.co</p>
              </div>
            </div>
          </div>

          {/* Right side - Contact Form */}
          <div className="md:w-2/3 bg-white p-8 rounded-lg md:ml-4">
            <h3 className="text-2xl font-bold text-gray-800 mb-4">Let’s Connect</h3>
            <p className="text-gray-500 mb-8">Join us on an exciting adventure to elevate your brand—reach out and watch the transformation unfold!</p>

            <form onSubmit={handleSubmit} className="space-y-6">
              {/* First and Last Name */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                  className="w-full p-4 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-600"
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-4 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-600"
                  required
                />
              </div>

              {/* Email and Phone */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                  if (!/\d/.test(e.key) && e.key !== 'Backspace') {
                 e.preventDefault();
                 }
                 }}
                  className="w-full p-4 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-600"
                  required
                />
                <input
                   type="text"
                   name="subject"
                   placeholder='Subject'
                   value={formData.subject}
                   onChange={handleChange}
                  className="w-full p-4 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-600"
                  required
               />
              </div>

             

              {/* Message */}
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder='Write Your Messege'
                rows="4"
                className="w-full p-4 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-600"
                
              ></textarea>

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full py-4 bg-teal-500 hover:bg-teal-600 text-white rounded-lg hover:text-gray-800 transition-colors"
                disabled={loading}>
            {loading ? "Sending..." : "Submit " }
              </button>
            </form>
            {error && <p>{error}</p>}
            {success && <p>We have received your query. Our team will contact you soon</p>}
          </div>
        </div>
      </div>
    </section>
    </>
    
  );
};

export default Contact;
