import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Service = () => {
  const logos = [
    { name: 'One Card', imageUrl: '/assets/onecard.png' },
    { name: 'Fibe', imageUrl: '/assets/fibe.jpg' },
    { name: 'JAR', imageUrl: '/assets/jar.webp' },
    { name: 'Kreditbee', imageUrl: '/assets/kredibee.png' },
    { name: 'Ajaib', imageUrl: '/assets/ajaib.png' },
    { name: 'Fi Money', imageUrl: '/assets/fimoney.png' },
    { name: 'Acko', imageUrl: '/assets/acko.png' },
    { name: 'Rupee', imageUrl: '/assets/rupee.png' },
    { name: 'Cadbury', imageUrl: '/assets/cadbury.png' },
    { name: 'Probo', imageUrl: '/assets/probo.png' },

  ];
  return (
    <>
     <Helmet>
      <title>Lime Brands - A Digital Driven Advertising Solutions</title>
      <meta  name='description' content='strategic planning and WhatsApp automation campaign execution, optimization,comprehensive reporting, data-driven deliver impactful results'/>
      <meta name='keywords' content='Digital Advertising Agency, Paid Media Services, Programmatic Advertising, Branding Campaigns, Digital Marketing Solutions, Online Advertising Experts, Social Media Advertising, PPC Management, Remarketing Services, WhatsApp Automation for Brands, Data-Driven Advertising, Digital Ad Campaign Optimization, Performance Marketing Agency, Video and Display Advertising' />
    </Helmet>
    <section  className="pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px] ">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-10 max-w-[510px] text-center lg:mb-20">
              <span data-aos="fade-up"  className="mb-2 block text-4xl font-semibold text-teal-600">
                Our Offering 
              </span>
              {/* <h2  data-aos="fade-up" data-aos-delay="500"className="mb-3 text-3xl font-bold leading-[1.2] text-dark  sm:text-4xl md:text-[40px]">
                Our Offering 
              </h2> */}
              <p data-aos="fade-up" data-aos-delay="500"className="text-base text-gray-500">
              We offer innovative, data-driven ad solutions that captivate audiences and drive measurable results. From social media to search and beyond, we turn your brand’s vision into impactful digital experiences that convert.
              </p>
            </div>
          </div>
        </div>

        <div data-aos="fade-up"  className="-mx-4 flex flex-wrap">
          <ServiceCard
            title="Programmatic Section"
            details="Programmatic display ads are automated, data-driven advertisements that target specific audiences in real time.  "
            icon="🛡️"
          />
          <ServiceCard
            title=" CTV Advertising"
            details="Gateway to reaching audiences where they increasingly consume content—on their televisions, through streaming platforms."
            icon="📺"
          />
          <ServiceCard
            title="Influencer Marketing"
            details="Influencer marketing harnesses the power of social media personalities to amplify your brands message."
            icon="👥" 
          />
          <ServiceCard
            title="Paid media solutions"
            details="Paid media solutions offer a powerful way to amplify your brand's presence through strategic ad placements."
            icon="💰"
          />
          <ServiceCard
            title="Digital PR solution"
            details="Digital PR solutions elevate your brand's online presence by crafting compelling narratives and building relationships."
            icon="📢"
            
          />
          <ServiceCard
            title="WhatsApp Automation"
            details="Customer engagement by delivering personalized messages at scale directly to users' smartphones."
            icon="💬"
          />
        </div>
      </div>
    </section> 
    <div className="overflow-hidden py-4 bg-teal-500">
      <div className="flex justify-between items-center animate-scroll space-x-6">
        {logos.map((logo, index) => (
          <div
            key={index}
            className="flex items-center flex-shrink-0 px-6 text-white text-lg font-bold opacity-75 hover:opacity-100"
          >
            <img
              src={logo.imageUrl}
              alt={logo.name}
              className="h-12 w-12 mr-4" // Adjust size and add margin for spacing
            />
            <span>{logo.name}</span>
          </div>
        ))}
      </div>
    </div>
          <section className="bg-gray-100 py-12 px-6 md:px-16 lg:px-24">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between gap-12">
        {/* Image Section */}
        <div className="flex justify-center md:w-1/2">
          <img data-aos="fade-left" data-aos-delay="500"
            src="/assets/service.jpeg" // Replace with the actual image URL or import
            alt="About Us Imag"
            className="w-72 h-auto md:w-full"
          />
        </div>

        {/* Text Section */}
        <div data-aos="fade-down" data-aos-delay="500" className="md:w-1/2 text-center md:text-left">
          <h2 className="text-4xl font-bold mb-4 text-gray-800">Service</h2>
          <p className="text-gray-500 mb-6">
          At Lime Brands, we provide a comprehensive suite of digital advertising services designed to elevate your brand and maximize your ROI. Our offerings include precision-targeted PPC campaigns that ensure your message reaches the right audience at the right time, engaging social media advertising that captures attention and fosters genuine connections, and programmatic display ads that deliver seamless, automated outreach across the web. We specialise in marketing strategies to re-engage potential customers, alongside impactful video and content creation that brings your brands story to life. Through continuous optimization and in-depth reporting, we ensure every campaign is data-driven, results-focused, and aligned with your business objectives.
          </p>
          <Link to='/contact-us'>
          <button className="text-white bg-teal-500 hover:bg-teal-700 px-4 py-2 rounded-full">
            Contact Us
          </button>
          </Link>
        </div>
      </div>

      {/* Contact Information Section */}
      {/* <div data-aos="fade-up" className="container mx-auto mt-12 grid grid-cols-1 md:grid-cols-3 gap-6">
        <div data-aos="fade-down" className="bg-teal-100 text-gray-700 p-6 rounded-lg text-center">
          <h4 className="text-lg font-bold mb-2">CALL US</h4>
          <p>1 (234) 567-891,</p>
          <p>1 (234) 987-654</p>
        </div>
        <div data-aos="fade-down" className="bg-orange-100 text-gray-700 p-6 rounded-lg text-center">
          <h4 className="text-lg font-bold mb-2">LOCATION</h4>
          <p>121 Rock Street, 21 Avenue, New York, NY</p>
          <p>92103-9000</p>
        </div>
        <div  data-aos="fade-down" className="bg-green-100 text-gray-700 p-6 rounded-lg text-center">
          <h4 className="text-lg font-bold mb-2">HOURS</h4>
          <p>Mon – Fri ..... 11 am – 8 pm, Sat, Sun ..... 6 am – 8 pm</p>
        </div>
      </div> */}
    </section>
    <section data-aos="fade-down" className="bg-white py-14 px-6 md:px-16 lg:px-24">
      {/* Container */}
      <div className="container mx-auto flex flex-col md:flex-row items-center gap-12">
        {/* Left Image Section */}
        <div className="relative w-full md:w-1/2">
          <img
            src="/assets/service12.jpeg" // Replace with actual image URL
            alt="Our Mission"
            className="w-full h-auto object-cover rounded-lg shadow-lg"
          />
          {/* Bottom Decorative Block */}
          <div className="absolute -bottom-6 -left-6 w-3/4 h-4 bg-teal-500 rounded"></div>
        </div>

        {/* Right Content Section */}
        <div className="w-full md:w-1/2">
          {/* Section Title */}
          <h2 className="text-3xl font-bold mb-4 text-gray-800">Empowering Brands Through Innovative Digital Advertising</h2>

          {/* Quote Section */}
          <blockquote data-aos="fade-down" className="text-gray-500 text-lg italic border-l-4 border-teal-500 pl-4 mb-6">
          At Lime Brands, our mission is to elevate brands in the digital landscape by crafting innovative advertising solutions that connect, inspire, and convert. We believe in the power of creativity and data-driven strategies, combining them to drive meaningful engagement and measurable results. By embracing the latest technologies and trends, we strive to empower businesses to reach their fullest potential and forge lasting relationships with their audiences. Together, we create campaigns that not only stand out but also make a difference.          </blockquote>

          {/* Author Info */}

          {/* Learn More Link */}
          {/* <a href="#" className="text-orange-500 font-semibold hover:underline">
            learn more
          </a> */}
        </div>
      </div>
    </section>
          </>
  );
};

export default Service;

const ServiceCard = ({ icon, title, details }) => { 
  return (
    <>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div className="mb-9 rounded-lg bg-white dark:bg-dark-2 p-10 shadow-md hover:shadow-lg md:px-7 xl:px-10">
          <div className="mb-4 flex h-[70px] w-[70px] items-center justify-center rounded-2xl text-4xl"> 
            {icon} {/* Use text-4xl or other sizes like text-5xl, etc. to adjust icon size */}
          </div>
          <h4 className="mb-[14px] text-2xl font-semibold text-gray-800">
            {title}
          </h4>
          <p className="text-base text-gray-500">{details}</p>
        </div>
      </div>
    </>
  );
};
