import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="bg-gray-50 py-12 px-6 md:px-20 lg:px-40">
      <div className="max-w-7xl mx-auto space-y-12">
        {/* Page Title */}
        <div className="text-center mt-10">
          <h1 className="text-4xl font-extrabold text-teal-600 mb-4 ">
            Terms and Conditions
          </h1>
          <p className="text-lg text-gray-600">
            Welcome to <span className="font-semibold text-teal-600">Lime Brands</span>. By engaging our digital advertising services, you agree to comply with the following terms and conditions.
          </p>
        </div>

        {/* Content Section */}
        <div className="space-y-8 text-left text-gray-700 leading-relaxed">
          {/* Services Provided */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Services Provided</h2>
            <p>
              We offer a range of digital advertising services, including but not limited to:
            </p>
            <ul className="list-disc list-inside ml-4 space-y-2 mt-2">
              <li>Paid media campaigns</li>
              <li>Programmatic advertising</li>
              <li>Social media marketing</li>
              <li>SEO and SEM services</li>
              <li>Branding and content creation</li>
              <li>Analytics and performance reporting</li>
            </ul>
            <p className="mt-4">
              The scope of services and deliverables will be outlined in the individual contract or agreement signed between you and <span className="font-semibold">Lime Brands</span>.
            </p>
          </div>

          {/* Payment Terms */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Payment Terms</h2>
            <p>
              Payment terms will be agreed upon in the individual contract and may include upfront payments, monthly retainers, or project-based fees. Payments must be made within the timeframe specified in the contract. Late payments may incur additional fees or service suspension.
            </p>
          </div>

          {/* Client Responsibilities */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Client Responsibilities</h2>
            <p>
              Clients are responsible for providing accurate and timely information necessary for the completion of services. This includes access to necessary accounts, content approvals, and communication with our team.
            </p>
          </div>

          {/* Intellectual Property */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Intellectual Property</h2>
            <p>
              All content, creatives, and materials produced by <span className="font-semibold">Lime Brands</span> for your campaigns remain the property of the agency until full payment is received. Upon payment, ownership rights will be transferred to you, unless otherwise agreed.
            </p>
          </div>

          {/* Confidentiality */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Confidentiality</h2>
            <p>
              We respect your confidentiality and will not disclose any proprietary or confidential information without prior consent. Similarly, you agree not to disclose any proprietary methodologies or trade secrets of <span className="font-semibold">Lime Brands</span>.
            </p>
          </div>

          {/* Termination of Services */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Termination of Services</h2>
            <p>
              Either party may terminate services with [Insert Notice Period] days' written notice. In the event of termination, you will be responsible for any work completed up until the termination date. Any unused portions of pre-paid services will be refunded, if applicable.
            </p>
          </div>

          {/* Limitations of Liability */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Limitations of Liability</h2>
            <p>
              While we strive for excellence in every campaign, <span className="font-semibold">Lime Brands</span> cannot guarantee specific results such as increased traffic, sales, or conversions. We will not be held liable for any indirect, consequential, or incidental damages resulting from the use of our services.
            </p>
          </div>

          {/* Third-Party Platforms */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Third-Party Platforms</h2>
            <p>
              We may utilize third-party platforms such as Google, Facebook, and others to execute campaigns. We are not responsible for any changes to these platforms or downtime that may affect the performance of your campaigns.
            </p>
          </div>

          {/* Amendments */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Amendments</h2>
            <p>
              <span className="font-semibold">Lime Brands</span> reserves the right to amend these terms and conditions at any time. Clients will be notified of any substantial changes.
            </p>
          </div>

          {/* Governing Law */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Governing Law</h2>
            <p>
              These terms and conditions shall be governed by and construed in accordance with the laws of <span className="font-semibold">[Your Country/State]</span>. Any disputes arising from the use of our services shall be subject to the exclusive jurisdiction of the courts in <span className="font-semibold">[Your Country/State]</span>.
            </p>
          </div>

          {/* Contact Information */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Contact Information</h2>
            <p>
              If you have any questions about these terms and conditions, please contact us at:
            </p>
            <ul className="list-disc list-inside ml-4 space-y-2 mt-2">
              <li>Email: <span className="font-semibold">[Your Email Address]</span></li>
              <li>Phone: <span className="font-semibold">[Your Phone Number]</span></li>
              <li>Address: <span className="font-semibold">[Your Physical Address]</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
