import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-50 py-12 px-6 md:px-20 lg:px-40">
      <div className="max-w-7xl mx-auto space-y-12">
        {/* Page Title */}
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-4 mt-10">Privacy Policy</h1>
          <p className="text-lg text-gray-600">
            At <span className="font-semibold text-teal-600">Lime Brands</span>, we are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner.
          </p>
        </div>

        {/* Content Section */}
        <div className="space-y-8 text-left text-gray-700 leading-relaxed">
          {/* Information We Collect */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Information We Collect</h2>
            <p>We may collect personal information that you provide directly to us when you:</p>
            <ul className="list-disc list-inside ml-4 space-y-2 mt-2">
              <li>Fill out forms on our website</li>
              <li>Subscribe to our newsletters</li>
              <li>Request a consultation or quote</li>
              <li>Engage with our services</li>
            </ul>
            <p className="mt-4">
              The types of information we may collect include, but are not limited to:
            </p>
            <ul className="list-disc list-inside ml-4 space-y-2 mt-2">
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Company name</li>
              <li>Payment information (if applicable)</li>
              <li>Any other information you choose to provide</li>
            </ul>
          </div>

          {/* How We Use Your Information */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">How We Use Your Information</h2>
            <p>We may use the information we collect for various purposes, including:</p>
            <ul className="list-disc list-inside ml-4 space-y-2 mt-2">
              <li>To provide, operate, and maintain our services</li>
              <li>To improve, personalize, and expand our services</li>
              <li>To understand and analyze how you use our website</li>
              <li>To communicate with you, including responding to your inquiries</li>
              <li>To process transactions and send you related information</li>
              <li>To send you newsletters, marketing communications, and promotional offers</li>
              <li>To detect, prevent, and address technical issues</li>
            </ul>
          </div>

          {/* Sharing Your Information */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Sharing Your Information</h2>
            <p>
              We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except to provide our services or as required by law. We may share your information with:
            </p>
            <ul className="list-disc list-inside ml-4 space-y-2 mt-2">
              <li>Service providers who assist us in operating our website or conducting our business</li>
              <li>Legal authorities, if required to comply with applicable laws</li>
            </ul>
          </div>

          {/* Cookies and Tracking Technologies */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Cookies and Tracking Technologies</h2>
            <p>
              Our website may use cookies and similar tracking technologies to enhance your experience. You can choose to accept or decline cookies through your browser settings. However, declining cookies may prevent you from accessing certain features of our website.
            </p>
          </div>

          {/* Data Security */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Data Security</h2>
            <p>
              We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure. While we strive to protect your personal information, we cannot guarantee its absolute security.
            </p>
          </div>

          {/* Your Rights */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Your Rights</h2>
            <p>Depending on your location, you may have certain rights regarding your personal information, including:</p>
            <ul className="list-disc list-inside ml-4 space-y-2 mt-2">
              <li>The right to access, correct, or delete your information</li>
              <li>The right to object to or restrict processing</li>
              <li>The right to withdraw consent at any time</li>
            </ul>
            <p className="mt-4">To exercise these rights, please contact us using the information provided below.</p>
          </div>

          {/* Changes to This Privacy Policy */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Changes to This Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website with a new effective date. We encourage you to review this Privacy Policy periodically for any updates.
            </p>
          </div>

          {/* Contact Information */}
          <div>
            <h2 className="text-2xl font-bold text-teal-600 mb-2">Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please contact us at:
            </p>
            <ul className="list-disc list-inside ml-4 space-y-2 mt-2">
              <li>Email: <span className="font-semibold">[Your Email Address]</span></li>
              <li>Phone: <span className="font-semibold">[Your Phone Number]</span></li>
              <li>Address: <span className="font-semibold">[Your Physical Address]</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
