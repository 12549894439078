import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="text-gray-800" style={{ backgroundColor: '#EEF1F6' }}>
      <div className="max-w-6xl mx-auto py-8 px-4 sm:px-6 lg:px-8 flex flex-wrap justify-between">
        {/* Logo and Description */}
        <div className="w-full sm:w-1/2 lg:w-1/4 mb-6 lg:mb-0">
        <div className="flex-shrink-0">
            <img
              className="h-22 w-36 object-contain"  // Adjust height and width explicitly
              src="/logo.jpeg"   // Update with correct image path
              alt="App Logo"
            />
          </div>
          <p className="text-gray-600 lg:mr-2 md:mr-2 mr-3">Transforming visions into vibrant digital realities that captivate, connect, and convert.</p>
         
        </div>

        {/* Company Section */}
        <div className="w-full sm:w-1/2 lg:w-1/4 mb-6 lg:mb-0">
          <h3 className="font-semibold mb-3">Company</h3>
          <ul>
          <li><a href="/" className="hover:text-gray-900">Home</a></li>
            <li><a href="/about-us" className="hover:text-gray-900">About Us</a></li>
            <li><a href="/service" className="hover:text-gray-900">Service</a></li>
            <li><a href="/contact-us" className="hover:text-gray-900">Contact</a></li>
          </ul>
        </div>

        {/* Support Section */}
        <div className="w-full sm:w-1/2 lg:w-1/4 mb-6 lg:mb-0">
          <h3 className="font-semibold mb-3">Support</h3>
          <ul>
            <li><a href="privacy-policy" className="hover:text-gray-900">Privacy Policy</a></li>
            <li><a href="/term-and-condition" className="hover:text-gray-900">Term And Condition</a></li>
          </ul>
        </div>

        {/* Contact Section */}
        <div className="w-full sm:w-1/2 lg:w-1/4 mb-6 lg:mb-0">
          <h3 className="font-semibold mb-3">Get In Touch</h3>
          <ul>
            {/* <li className="flex items-center space-x-2">
              <span className="text-gray-600">✉️</span>
              <span>support@appie.com</span>
            </li>
            <li className="flex items-center space-x-2 mt-2">
              <span className="text-gray-600">📞</span>
              <span>+64(2) 342 762 44</span>
            </li> */}
            <li className="flex items-center space-x-2 mt-2">
              <span className="text-gray-600">📍</span>
              <span>Unit No. 316, 3rd Floor, Tower-c, Klj Noida One, Block-b, Plot No. 8, Sector 62, Noida, Gautam Buddha Nagar, Noida, Uttar Pradesh, India, 201301  </span>
            </li>
          </ul>
        </div>
      </div>

      <div className="bg-gray-200 py-4 mt-4">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        <div className="flex space-x-4">
    <FaFacebook className="text-3xl text-blue-600 hover:text-blue-800" />
  
    <FaTwitter className="text-3xl text-blue-400 hover:text-blue-600" />
 
    <FaInstagram className="text-3xl text-pink-500 hover:text-pink-700" />

    <FaLinkedin className="text-3xl text-blue-700 hover:text-blue-900" />
 
</div>

          <span className="text-gray-500 text-sm">&copy; 2024 Lime Brands. All rights reserved.</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
