import React from 'react';

const ResponsiveDesign = () => {
  return (
    <div data-aos="fade-up" 
    className="container mx-auto p-5">
      {/* Main content container */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
        {/* Left: Image and video button */}
        <div className="relative">
          {/* Background for the tablet and phone */}
          <img 
            src="/assets/home12.jpeg" 
            alt="Tablet and Phone"
            className="w-full h-auto rounded-lg shadow-lg"
          />
          {/* Video play button */}
          {/* <div className="absolute inset-0 flex items-center justify-center">
            <button className="bg-white rounded-full p-4 shadow-md">
              <img 
                src="https://appiev.quomodosoft.com/static/media/video-thumb-1.17e64467.jpg" 
                alt="Play" 
                className="w-10 h-10"
              />
            </button>
          </div> */}
         
        </div>

        {/* Right: Mobile avatar customization */}
        <div className="flex justify-center">
          <img 
            src="/assets/home13.jpeg" 
            alt="Mobile Avatar" 
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
        
      </div>
      </div>
   
  );
};

export default ResponsiveDesign;
