import React from "react";
import { FaPaintBrush, FaChartLine, FaHandsHelping, FaShieldAlt, FaChartBar, FaBookOpen } from "react-icons/fa";
import PlatformsGrid from "../Platform/PlatformsGrid";
import { motion } from 'framer-motion';
import { Helmet } from "react-helmet";


const About = () => {
    
  const values = [
    {
      icon: <FaPaintBrush className="text-5xl text-teal-500" />,
      title: "Creativity Unleashed",
      description: " We believe in the power of imagination, pushing boundaries to create innovative campaigns that captivate and inspire.",
      bgColor: "bg-teal-100",
    },
    {
      icon: <FaHandsHelping className="text-5xl text-orange-500" />,
      title: "Collaboration & Connection",
      description: "Together, we foster a culture of teamwork, where ideas flow freely, and every voice matters in shaping our shared success.",
      bgColor: "bg-orange-100",
    },
    {
      icon: <FaChartLine className="text-5xl text-green-500" />,
      title: "Data-Driven Decisions",
      description: "We harness the magic of analytics to inform our strategies, ensuring every campaign is backed by insights that drive real results.",
      bgColor: "bg-green-100",
    },
   
    {
      icon: <FaShieldAlt className="text-5xl text-teal-500" />,
      title: "Integrity & Transparency",
      description: "We operate with honesty and openness, building trust through clear communication and ethical practices.",
      bgColor: "bg-teal-100",
    },
    {
      icon: <FaBookOpen className="text-5xl text-orange-500" />,
      title: "Impactful Storytelling",
      description: "We are passionate about crafting narratives that resonate, creating meaningful connections between brands and audiences.",
      bgColor: "bg-orange-100",
    },
    {
      icon: <FaChartBar className="text-5xl text-green-500" />,
      title: "Continuous Growth",
      description: "We embrace a mindset of lifelong learning, always evolving to stay ahead of industry trends and deliver cutting-edge solutions.",
      bgColor: "bg-green-100",
    },
   
  ];

  return (
    <>
    <Helmet>
      <title> Lime Brands - A Digital Driven Advertising Solutions</title>
      <meta  name='description' content='Empowering brands dynamic digital landscape, dedicated to transparency, collaboration, ongoing enhancement. crafting meaningful and impactful advertising strategies driving growth elevating brands online presence.'/>
      <meta name='keywords' content='Digital Advertising Agency, Paid Media Services, Programmatic Advertising, Branding Campaigns, Digital Marketing Solutions, Online Advertising Experts, Social Media Advertising, PPC Management, Remarketing Services, WhatsApp Automation for Brands, Data-Driven Advertising, Digital Ad Campaign Optimization, Performance Marketing Agency, Video and Display Advertising' />
    </Helmet>
    <div className="flex w-full flex-col gap-[100px] bg-white-A700 md:gap-[75px] sm:gap-[50px]">
        <div className="flex flex-col items-center gap-[100px] md:gap-[75px] sm:gap-[50px]">
          <div className="container-xs flex flex-col gap-[38px] md:p-5">
            <div className="flex flex-col gap-4">
              <h1 className="text-center text-gray-800 text-4xl md:text-7xl leading-[140%] tracking-[-1.08px] py-20">
                <>
                
Your Partners in  <br />
Digital Transformation
                </>
              </h1>
              <p className="text-center text-lg  text-gray-500 leading-[180%]">
                <>Welcome to Lime Brand, where pixels meet passion and strategy dances with creativity!<br /> We are not just a digital advertising agency; we are storytellers, innovators,<br /> and your dedicated partners on this exhilarating journey through the ever-evolving digital landscape. 
                  
                </>
              </p>
            </div>
            <div className="flex flex-col gap-6 md:flex-row">
              <img src="/assets/about1.png"
                alt="imag"
                className="h-[300px] md:h-[400px] w-full md:w-[50%] rounded-[10px] object-cover"
              />
              <img
                src="/assets/about2.png"
                alt="imag"
                className="h-[300px] md:h-[400px] w-full md:w-[50%] rounded-[10px] object-cover"
              />
            </div>
            <div className="flex flex-col gap-6 md:flex-row">
              <img
                src="/assets/about3.png"
                alt="imag"
                className="h-[300px] md:h-[400px] w-full md:w-[50%] rounded-[10px] object-cover"
              />
              <img
                src="/assets/about4.png"
                alt="imag"
                className="h-[300px] md:h-[400px] w-full md:w-[50%] rounded-[10px] object-cover"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto py-16 px-6 space-y-16 text-center">
      {/* Hero Section */}
      <div className="relative">
        <h1 className="text-4xl md:text-5xl font-bold text-gray-800 mb-4 animate__animated animate__fadeInUp">
          Your Partners in Digital Transformation
        </h1>
        <p className="text-lg text-gray-500 animate__animated animate__fadeInUp animate__delay-1s">
          Welcome to <span className="font-bold text-teal-600">Lime Brands</span>, where pixels meet passion, and creativity meets strategy. We’re not just a digital advertising agency; we’re storytellers and your partners on an exciting journey through the digital landscape.
        </p>
      </div>

      {/* Split Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Our Canvas */}
        <div className="bg-teal-100 p-10 rounded-lg shadow-lg transition-all duration-500 hover:shadow-2xl transform hover:scale-105">
          <h2 className="text-3xl font-bold text-teal-600 mb-4">Our Canvas</h2>
          <p className="text-gray-500">
            In a world filled with noise, we tell your brand's story using vibrant visuals and compelling narratives. Every campaign is a unique masterpiece designed to inspire action and deliver results. Let us know if you'd like variations or different styles!
          </p>
        </div>

        {/* The Dream Team */}
        <div className="bg-orange-100 p-10 rounded-lg shadow-lg transition-all duration-500 hover:shadow-2xl transform hover:scale-105">
          <h2 className="text-3xl font-bold text-orange-500 mb-4">The Dream Team</h2>
          <p className="text-gray-500">
          Our diverse team of digital wizards—designers, strategists, copywriters, and analysts—brings together a kaleidoscope of skills and perspectives. Together, we blend artistry with analytics, ensuring that every decision is backed by data while keeping creativity at the forefront.
          </p>
        </div>

        {/* Our Playground */}
        <div className=" bg-orange-100 p-10 rounded-lg shadow-lg transition-all duration-500 hover:shadow-2xl transform hover:scale-105">
          <h2 className="text-3xl font-bold text-orange-600 mb-4">Our Playground</h2>
          <p className="text-gray-500">
          From dynamic social media campaigns to innovative video content and cutting-edge programmatic ads, we thrive in the playground of digital marketing. We leverage the latest technologies and trends to ensure your brand stands out, making waves in an ocean of opportunity.
          </p>
        </div>

        {/* Your Success */}
        <div className="bg-teal-100 p-10 rounded-lg shadow-lg transition-all duration-500 hover:shadow-2xl transform hover:scale-105">
          <h2 className="text-3xl font-bold text-teal-600 mb-4">Your Success is Our Mission</h2>
          <p className="text-gray-500">
          We do not just work for you; we work with you. Your goals become our goals, and your success fuels our passion. With a commitment to transparency, collaboration, and continuous improvement, we aim to build long-lasting partnerships that drive sustainable growth.
          </p>
        </div>
      </div>
    </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10 px-6">
  <div className="flex justify-center items-center ">
    <img src="/assets/about5.jpeg" alt="imag" className="rounded-lg object-cover w-full md:w-auto md:h-auto" />
  </div>
  <div className="flex flex-col justify-center gap-10  ">
    <div>
      <h1 className="text-3xl md:text-4xl lg:text-4xl font-bold tracking-wide text-gray-800 mb-4   "> Igniting the Future of Digital Engagement</h1>
      <p className="text-lg md:text-xl leading-relaxed text-gray-500">
      At Lime Brand, we envision a digital landscape where brands and audiences connect authentically, transcending traditional boundaries through innovation and creativity
      </p>
        <p className="text-lg md:text-xl leading-relaxed mt-2 text-gray-500">
      A Symphony of Innovation
We see a future where technology and creativity harmoniously coexist, composing a symphony of compelling narratives and personalized interactions that resonate deeply with every consumer. By leveraging cutting-edge tools and data-driven insights, we aim to craft campaigns that not only engage but also inspire action.
      </p>
    </div>
  </div>
</div>
<PlatformsGrid />
        <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-800">We are driven by our values</h2>
          <p className="text-gray-500 mt-4 text-2xl">The Heartbeat of Lime Brand</p>
        </div>

        {/* Values Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {values.map((value, index) => (
             <motion.div 
             key={index} 
             initial={{ opacity: 0, y: 30 }}
             whileInView={{ opacity: 1, y: 0 }}
             transition={{ duration: 0.5, delay: index * 0.2 }}>
            <div
              key={index}
              className={`p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ${value.bgColor} flex flex-col items-center text-center group`}
            >
             
              <div className="w-16 h-16 rounded-full flex items-center justify-center mb-4 transform transition-transform duration-300 group-hover:scale-110">
                {value.icon}
              </div>
           
              <h3 className="text-xl font-bold mb-2">{value.title}</h3>
            
              <p className="text-gray-600">{value.description}</p>
            </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
    </>
  );
};

export default About;
